import React from "react";
import "./ContactUs.css";
import map from "../images/map.png";
import map1 from "../images/egy map.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Phone } from "@mui/icons-material";
import x from "../images/logo.svg";

function ContactUs() {
  return (
    <div className="contact">
      <div className="conatact_container">
        <div className="conact_form">
          <p className="CONTACTFORMHEADER">Get In Touch With</p>

          <img src={x} alt="igrc" style={{ height: 50, marginTop: 25 }} />

          <div className="cc">
            <span className="cc1">
              <a href="mailto:info@igrc2.com" target="_blank" rel="noreferrer">
                <MailOutlineIcon
                  style={{ width: 75, height: 75 }}
                  className="ccicon"
                />{" "}
              </a>
              <span className="cctxt">
                <a
                  href="mailto:info@igrc2.com"
                  target="_blank"
                  rel="noreferrer"
                  className="cctxt"
                >
                  MAIL
                </a>
              </span>
            </span>

            <span className="cc2">
              <a href="tel:0505712227" target="_blank" rel="noreferrer">
                <Phone style={{ width: 75, height: 75 }} className="ccicon" />{" "}
              </a>
              <span className="cctxt">
                <a
                  href="tel:0505712227"
                  target="_blank"
                  rel="noreferrer"
                  className="cctxt"
                >
                  PHONE
                </a>
              </span>
            </span>
          </div>
          <form style={{ display: "none" }}>
            <input
              type="text"
              placeholder="Full Name"
              name="user_name"
              className="cinput"
            />
            <input
              type="text"
              placeholder="Email"
              name="user_email"
              className="cinput"
            />
            <input
              type="text"
              placeholder="Message address:"
              name="user_subject"
              className="cinput"
            />
            <textarea
              rows="10"
              placeholder="Write your message here…"
              name="message"
              className="ctextarea"
            />
            <button type="submit" value="Send" className="button1">
              Send
            </button>
          </form>
        </div>

        <div className="conatct_loctaions">
          {/* <div className="contact1">
            <span className="cxcx">
              <h1 className="contact_header">KSA</h1>
            </span>

            <span className="contact_info_container">
              <div className="conatact_infoo">
                <span className="con_txt">
                  <span>
                    <LocationOnIcon style={{ color: "#EDA24F" }} />
                  </span>
                  <p>
                    Riyadh’s Business Gate, Level 1,
                    <br /> Building 7, Zone A - P.O Box <br />
                    93597 - Airport road, Riyadh
                    <br /> 11683, KSA
                  </p>
                </span>

                <span className="con_txt">
                  {" "}
                  <span>
                    <MailOutlineIcon style={{ color: "#EDA24F" }} />
                  </span>{" "}
                  <p> mail: info@igrc2.com </p>{" "}
                </span>
                <span className="con_txt">
                  {" "}
                  <span>
                    <AddIcCallIcon style={{ color: "#EDA24F" }} />
                  </span>
                  <p>
                    {" "}
                    Landline Tel :011-261-1474 <br /> Phone: +966 505712227{" "}
                  </p>{" "}
                </span>
              </div>
            </span>

            <span className="con_xx">
              <img
                src={map}
                alt="location"
                className="con_mapa"
                loading="lazy"
              />
            </span>
          </div> */}

          <div className="contact2">
            <span className="cxcx">
              <h1 className="contact_header">Saudi Arabia</h1>
            </span>

            <span className="contact_info_container">
              <div className="conatact_infoo">
                {/* <span className="con_txt">
                  <span>
                    <LocationOnIcon style={{ color: "#EDA24F" }} />
                  </span>
                  <p>
                    Appartement 42, 4th floor, Building 2<br /> Emirate Al-Obour
                    , Salah Salem Street,
                    <br /> Misr al-jadida, Cairo, Egypt, P.O Box 11811,
                    <br /> CR. NO: 157214, VAT. NO: 607-033-355
                  </p>
                </span> */}

                <span className="con_txt">
                  {" "}
                  <span>
                    <MailOutlineIcon style={{ color: "#EDA24F" }} />
                  </span>{" "}
                  <p> mail: info@igrc2.com </p>{" "}
                </span>
                <span className="con_txt">
                  {" "}
                  <span>
                    <AddIcCallIcon style={{ color: "#EDA24F" }} />
                  </span>
                  <p>
                    {" "}
                    {/* <span>Landline Tel :+2022626813 </span> */}
                    {/* <br /> */}
                    Phone: 0505712227{" "}
                  </p>{" "}
                </span>
              </div>
            </span>

            {/* <span className="con_xx">
              <img
                src={map1}
                alt="location"
                className="con_mapa"
                loading="lazy"
              />
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
