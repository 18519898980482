import React from 'react'
import './CF.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import img1 from '../images/1-1.jpg';
import img2 from '../images/2.jpg';
import img3 from '../images/3.jpg';



function Carousel_Front() {
  return (
    <div>
        
         <Carousel
         autoPlay={true}
         showStatus={false}
         showThumbs={false}
        >
                <div>
                    <img src={img1} alt="1" loading='lazy'/>
                </div>
                {/* <div>
                    <img src={img2} alt="2" loading='lazy'/>
                    
                </div> */}
                <div>
                    <img src={img3} alt="3" loading='lazy'/>
                </div>
            </Carousel>
    </div>
  )
}

export default Carousel_Front