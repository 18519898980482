import React from 'react'
import './SoultionInfo.css'
import x from '../images/1644929821096.jpg'
function Pay({Maintitle,logo,link}) {
  return (
      <div className='soltions' >
          <div className='solutionContainer'>
              <div className='solution_bg'>
                  <div className='solution_bg_container'>
                  <span className='solution_left'><p>{Maintitle}</p></span>
                  <span className='solution_right'> <img src={logo} alt={Maintitle} style={{height:40}}/></span>
                  </div>
              </div>

              <div className='solution_desc'>
                  <div className='solution_desc_conatianer'>
                      <span className='SDESC'>
                          <img src={x} alt={Maintitle}/>
                         
                      </span>
                      <div className='learnMore'><a href={link} target='_blank' rel="noreferrer"> Learn More ...</a></div>
                  </div>
              </div>

          </div>

      </div>
  )
}

export default Pay