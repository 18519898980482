import React, { useState } from 'react'
import './Drop.css'
import {Link } from 'react-router-dom'
import SideDropDown from './SideDropDown'
import { items1, items2, items3, items4, items5 } from '../Data';

function DropDown() {
  const [hover2 ,setHOver2] = useState(false);
  const [hover21 ,setHOver21] = useState(false);
  const [hover22 ,setHOver22] = useState(false);
  const [hover23 ,setHOver23] = useState(false);
  const [hover24 ,setHOver24] = useState(false);
  const showmenu2=()=>{
    setHOver2(true)
}
const hidemenu2 =()=>{
  setHOver2(false)
}
const showmenu21=()=>{
  setHOver21(true)
}
const hidemenu21 =()=>{
  setHOver21(false)
}
const showmenu22=()=>{
  setHOver22(true)
}
const hidemenu22 =()=>{
  setHOver22(false)
}
const showmenu23=()=>{
  setHOver23(true)
}
const hidemenu23 =()=>{
  setHOver23(false)
}
const showmenu24=()=>{
  setHOver24(true)
}
const hidemenu24 =()=>{
  setHOver24(false)
}
  return (
    <div className='drop'>
      <div className="drop_container">
        <ul className='dropmenu'>
          <li className='dropitem' onMouseLeave={hidemenu2} >
          <Link to="#" className='drop_links'
          onMouseEnter={showmenu2}
          > Cryptography and Hardware security Module (HSM) </Link>
          {hover2?(<SideDropDown items={items1}/>):(<></>)}
          </li>

          <li className='dropitem' onMouseLeave={hidemenu21} >
          <Link to="#" className='drop_links'
           onMouseEnter={showmenu21}
          > Data Protection</Link>
          {hover21?(<SideDropDown items={items2}/>):(<></>)}
          </li>

          <li className='dropitem' onMouseLeave={hidemenu22} >
          <Link to="#" className='drop_links'
           onMouseEnter={showmenu22}> End-Point Security</Link>
          {hover22?(<SideDropDown items={items3}/>):(<></>)}
          </li>

          <li className='dropitem'>
          <Link to="/vigi" className='drop_links'> GRC Tool</Link>
          </li>


          <li className='dropitem' style={{borderBottom:'0px solid red'} } onMouseOut={hidemenu24} >
          <Link to="#" className='drop_links' onMouseEnter={showmenu24}> SIEM</Link>
          {hover24?(<SideDropDown items={items5}/>):(<></>)}
          </li>
        </ul>
      </div>
    </div>
  )
}

export default DropDown