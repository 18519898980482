import React from 'react'
import { jobs } from '../Data'
import './Career.css'
import CareerItem from './CareerItem'

function Career() {
  return (
      <div className='career'>
          <div className='career_bg'>
              <p className='carrer_text' >WE’RE HIRING!</p>
              
              <p className='career_desc'>
              <br/>
              <strong style={{fontWeight:400}}>#JOIN THE NEXT TECH COMPANY</strong> 
              <br/><br/>
            THROUGH BUILDING GREAT Tech team IN GREAT WAYS, WE ALWAYS EXCEED OUR CUSTOMERS’
             EXPECTATIONS BY CONTINUOUSLY PROVIDING BEST FIRST-CLASS SOLUTIONS TO EFFECTIVELY ENHANCE OUR CUSTOMERS’
              BUSINESSES. EQUIVALENTLY, WE AIM TO BE A GREAT EMPLOYER BY CONSTANTLY DEVELOPING OUR TEAMS AND CREATING 
              THE MOST ENGAGING WORK ENVIRONMENT.      
            </p></div>

          <div className='jobs'>
              <div className='jobs_container'>
                  {jobs.map(item=>(
                       <CareerItem
                       content={item.content}
                       level={item.level}
                       kind={item.kind}
                       posted={item.posted}
                       title={item.title}
                       type={item.type}
                       key={item.index}/>

                  ))}
                  
              </div>
          </div>
      </div>
  )
}

export default Career