import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import SoultionsInfo from '../components/SoultionsInfo'
import { DG2txt1, DG2txt2 } from '../Data'

function DG2() {
  return (
    <>
    <Navbar/>
    <SoultionsInfo
    logo={"https://digitalguardian.com/sites/all/themes/vermillion/images/dg_hs_logo_white_pink_square.png"}
    Maintitle={"Data Loss Prevention"}
    desc1={DG2txt1}
    desc2={DG2txt2}
    link={"https://digitalguardian.com/"}/>
    <Footer/>
    </>
  )
}

export default DG2