import React from 'react'
import {Link } from 'react-router-dom'
import './Drop.css'

function SideDropDown({items}) {
  
  return (
    <div className='sidedrop'>
      <div className='bg'></div>
        <div className="sidedrop_container">
            <ul className='sidedropmenu'>
              {items.map(item=>(<li className='sidedropitem'>
                {item.content[3]?
                ( <Link to={item.link} className='drop_links'> {item.content} </Link>):
                ( <Link to={item.link} className='drop_links' > {item.content} </Link>)}
               
                </li>))}
                
            </ul>
        </div>
    </div>
  )
}

export default SideDropDown