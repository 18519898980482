import React from 'react'
import Navbar from '../components/Navbar';

import Footer from '../components/Footer';
import Career from '../components/Career';

function JoinUs() {
  return (
    <>
    <Navbar/>
    <Career/>
    <Footer/>
    </>
  )
}

export default JoinUs