import React from "react";
import CardItem from "./CardItem";
import "./Why_IGRC.css";
import { why_data } from "../Data";

function Why_IGRC() {
  return (
    <div className="why">
      <div className="why_con">
        <div className="why_txt">
          <h1 className="question">
            WHY <br />
            IGRC2?
          </h1>
        </div>
        <div className="why_cards">
          {why_data.map((item) => (
            <CardItem
              key={item.id}
              img={item.img}
              title={item.title}
              title2={item.title2}
              list1={item.list1}
              title3={item.title3}
              list2={item.list2}
              label={item.label}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Why_IGRC;
