import React from 'react'
import Footer from '../components/Footer'
import IbmInfo from '../components/IbmInfo'
import Navbar from '../components/Navbar'


function IBM() {
  return (
    <>
    <Navbar/>
    <IbmInfo
    logo={"https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/IBM_logo.svg/300px-IBM_logo.svg.png"}/>
    <Footer/>
    </>
  )
}

export default IBM