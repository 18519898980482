import React, { Fragment } from "react";
import { aboutROW1Data, aboutROW2Data } from "../Data";
import "./About.css";
import About_CardItem from "./About_CardItem";

function About_US() {
  return (
    <div className="About">
      <div className="about_container">
        <div className="about_txt">
          <h1 className="about_head">IGRC² Values</h1>
          <p className="about_desc">
            Providing A Competitive Value Proposition <br /> For
          </p>
        </div>

        <div className="about_cards">
          <div className="about_cards_container">
            <div className="first_row_carts">
              {aboutROW1Data.map((item) => (
                <About_CardItem
                  key={item.id}
                  title={item.title}
                  img={item.img}
                />
              ))}
            </div>
            <hr style={{ marginLeft: 50, width: 1000 }} />
            <div className="second_row_carts">
              {aboutROW2Data.map((item) => (
                <About_CardItem
                  key={item.id}
                  title={item.title}
                  img={item.img}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About_US;
