import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import TrainingFormComponent from "../components/Training/form";

function TrainingProgram() {
  return (
    <>
      <Navbar />
      <TrainingFormComponent />
      <Footer />
    </>
  );
}

export default TrainingProgram;
