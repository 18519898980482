import React from 'react'
import './SoultionInfo.css'

function SoultionsInfo({Maintitle,logo,title1,title2,title3,desc1,desc2,desc3,link}) {
  return (
      <div className='soltions' >
          <div className='solutionContainer'>
              <div className='solution_bg'>
                  <div className='solution_bg_container'>
                  <span className='solution_left'><p>{Maintitle}</p></span>
                  <span className='solution_right'> <img src={logo} alt={Maintitle} style={{height:40}} loading='lazy'/></span>
                  </div>
              </div>

              <div className='solution_desc'>
                  <div className='solution_desc_conatianer'>
                      <span className='SDESC'>
                          {title1?(<><strong>{title1} </strong> <br/> <br/></>):(<></>)}
                          {desc1?(<>{desc1}<br/> <br/> <br/></>):(<></>)}

                         {title2?(<><strong>{title2} </strong> <br/> <br/></>):(<></>)}
                         {desc2?(<>{desc2}<br/> <br/> <br/></>):(<></>)}

                            {title3?(<><strong>{title3} </strong> <br/> <br/></>):(<></>)}
                            {desc3?(<>{desc3}<br/> <br/> <br/></>):(<></>)}
                      </span>
                      <div className='learnMore'><a href={link} target='_blank' rel="noreferrer"> Learn More ...</a></div>
                  </div>
              </div>

          </div>

      </div>
  )
}

export default SoultionsInfo