import React from "react";
import "./Our_partners.css";
import { partners } from "../Data";
import styled from "styled-components";
import Carousel from "react-elastic-carousel";

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 100%;
  margin: 0 15px;
  font-size: 4em;
`;

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];
function Our_partners() {
  return (
    <div className="partner">
      <div className="par_con">
        <h1 className="par_head">OUR PARTNERS</h1>
        <div className="par_carsouel">
          <Carousel
            breakPoints={breakPoints}
            showArrows={false}
            showEmptySlots={false}
            enableAutoPlay={true}
            autoPlaySpeed={2000}
            enableMouseSwipe={true}
            pagination={false}
            enableSwipe={true}
            preventDefaultTouchmoveEvent={false}
            enableTilt={true}
            className="xxxx"
          >
            {partners.map((item) => (
              <Item key={item.id}>
                <a href={item.link} target="_blank" rel="noreferrer">
                  <img src={item.image} alt="partner" loading="lazy" />
                </a>
              </Item>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Our_partners;
