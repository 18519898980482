import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import SoultionsInfo from '../components/SoultionsInfo'
import { trendmecrodata } from '../Data'

function TrendMecro() {
  return (
    <>
    <Navbar/>
    <SoultionsInfo
    logo={"https://marvel-b1-cdn.bc0a.com/f00000000017219/www.trendmicro.com/content/dam/trendmicro/global/en/global/logo/logo-desktop.png"}
    Maintitle={trendmecrodata[0]}
    title1={trendmecrodata[1]}
    desc1={trendmecrodata[2]}
    title2={trendmecrodata[3]}
    desc2={trendmecrodata[4]}
    title3={trendmecrodata[5]}
    desc3={trendmecrodata[6]}
    link={"https://www.trendmicro.com/en_no/about/newsroom/press-releases/2018/trend-micro-redefines-endpoint-security-with-trend-micro-apex-one.html"}/>
    <Footer/>
    </>
  )
}

export default TrendMecro