import React from 'react'
import './CareerItem.css'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

function CareerItem({title,content,type,kind,level , posted}) {
  return (
  <div className='C_Item'>
      <div className='C_Item_container'>
          <div className='C_Item_info'>
              <p className='C_Item_header'> {title}</p>
              <p className='C_Item_desc'> {content}</p>
          </div>
          <div className='C_Item_icons'>
              <div className='detials'>
              <span>{type}</span>
              <span>{kind}</span>
              <span>  {level}</span>
              <span>  {posted}</span>
              </div>
              <div className='CIicon'>
                  <a href='mailto:Careers@igrc2.com' className='links'>
                  <button className='CIBU'>APPLY <KeyboardArrowRightIcon/> </button>
                  </a>
              </div>
              
          </div>

      </div>
  </div>
  )
}

export default CareerItem