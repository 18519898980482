import React, { useState } from 'react'
import { servicesRow1, servicesRow2, servicesRow3 } from '../Data';
import './Servcies.css'
import ServiceCart from './ServiceCart';

function ServicePage() {
    const [click1 ,setClick1] = useState(true);
    const handleitem =()=>{
        setClick1(!click1);
        
    }


    
  return (
    <>
    <div className='services'>
        <div className='ser_bg'> 
        <div className='ser_text'>IGRC<sup>2</sup> SERVICES</div>
        <div className='ser_desc'>
        IGRC<sup>2</sup> was established in 2020 by a group of regional youth entrepreneurs to assume a business-driven approach of talents scale-up, offer consumption-based services and provide agilely seamless integration with large-scale of corporate projects.
        </div>
        <a href='mailto:info@igrc2.com' className='link'>
        <button className={`ser_button${click1 ? "1" : "2"}`} onClick={handleitem} >{click1?
        (<>Request Qoute</>):
        (<>Learn More</>)} </button> </a>
        </div>


        <div className='ser1'>
            <div className='ser1_container'>
                {
                    servicesRow1.map((item,index)=>(<ServiceCart category={2} logo={item.img} title={item.title} desc={item.desc} key={index}/>))
                }
                
            </div>
        </div>


        
        <div className='ser2'>
            <span className='headerrr'>
            <h1 className='ser_header'>INTEGRATED GOVERNANCE, RISK & COMPLIANCE</h1>
            </span>
     
            <div className='ser1_container'>
                
            {
                    servicesRow2.map((item,index)=>(<ServiceCart category={2} logo={item.img} title={item.title} desc={item.desc} key={index}/>))
                }
                
            </div>
        </div>


        
        <div className='ser1'>
        <span className='headerrr'>
            <h1 className='ser_header'> RISK MANGMENT</h1>
            </span>
            <div className='ser1_container'>
            {
                    servicesRow3.map((item,index)=>(<ServiceCart category={2} logo={item.img} title={item.title} desc={item.desc} key={index}/>))
                }
                
            </div>
        </div>

    </div>
    </>
  )
}

export default ServicePage