import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import VIGIHSM from '../components/VIGIHSM'

function VIGI() {
  return (
    <>
    <Navbar/>
    <VIGIHSM
    logo={"https://vigitrust.com/wp-content/themes/vigitrust-website-theme/assets/images/vigitrust-logo.png"}/>
    <Footer/>
    </>
  )
}

export default VIGI