import React from 'react'
import './SoultionInfo.css'

function IbmInfo({logo}) {
  return (
    
    <div className='soltions' style={{height:123+'vh'}}>
    <div className='solutionContainer'>
        <div className='solution_bg' style={{height:100+'px'}}>
            <div className='solution_bg_container'>
            <span className='solution_left'><p>IBM</p></span>
            <span className='solution_right'> <img src={logo} alt="IBM" style={{height:40}} loading='lazy'/> </span>
            </div>
        </div>

        <div className='solution_desc'>
            <div className='solution_desc_conatianer'>
                <span className='SDESC'>
                IBM's security intelligence and analytics platform enable organizations to detect, prioritize and neutralize cyber threats that penetrate the perimeter or originate from within.
                <br/><br/>
                Products
                <br/><br/>
                <ul>
                    <strong>Next-Gen SIEM</strong>
                    <li>Unified platform for advanced detection & response</li>
                    <br/><br/>
                    <strong>Security Analytics</strong>
                    <li>Holistic threat analytics & compliance automation</li>
                    <br/><br/>
                    <strong>Log Management</strong>
                    <li>Centralized visibility into all log and machine data, at any scale</li>
                    <br/><br/>
                    <strong>Network Forensics</strong>
                    <li>Real-time deep packet analytics and full capture</li>
                    <br/><br/>
                    <strong> Endpoint Monitoring</strong>
                    <li>Real-time user, file application, and system behavior monitoring</li>
                </ul>
                </span>

                <div className='learnMore'><a href='https://www.ibm.com/eg-en/security/security-intelligence'  target='_blank' rel="noreferrer" > Learn More ...</a></div>
            </div>
        </div>

    </div>

</div>
  )
}

export default IbmInfo