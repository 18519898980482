import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import ServicePage from '../components/ServicePage'

function Services() {
  return (
      <>
    <Navbar/>
    <ServicePage/>
    <Footer/>
    </>
  )
}

export default Services