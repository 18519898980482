import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import SoultionsInfo from '../components/SoultionsInfo'
import { KEYtxt } from '../Data'

function KEY() {
  return (
    <>
    <Navbar/>
    <SoultionsInfo
    Maintitle={"Key & HSM Management"}
    logo={"https://utimaco.com/themes/custom/utimaco/assets/img/utimaco-logo.svg"}
    desc1={KEYtxt}
    link={"https://utimaco.com/products/categories/hsm-key-management"}/>
    <Footer/>
    </>
  )
}

export default KEY