import React from "react";
import "./AboutCart.css";

function About_CardItem({ title, img }) {
  return (
    <>
      <div className="About_card_item">
        <div className="About_Cart_item_link">
          <figure className="About_card_item_pic_wrab">
            <img className="About_card_item_img" src={img} alt="jj" />
          </figure>
          <span className="About_Cart_txt"> {title}</span>
        </div>
      </div>
    </>
  );
}

export default About_CardItem;
