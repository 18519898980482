import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import SoultionsInfo from '../components/SoultionsInfo'
import { DGtxt } from '../Data'

function DG() {
  return (
    <>
    <Navbar/>
    <SoultionsInfo
    logo={"https://digitalguardian.com/sites/all/themes/vermillion/images/dg_hs_logo_white_pink_square.png"}
    Maintitle={"Data Classification"}
    desc1={DGtxt}
    link={"https://digitalguardian.com/"}/>
    <Footer/>
    </>
  )
}

export default DG