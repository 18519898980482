import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Pay from '../components/Pay'

function HSM() {
  return (
    <>
    <Navbar/>
    <Pay
    Maintitle={"Utimaco brochure"}
    link={"https://utimaco.com/products/categories/hsm-key-management"}
    logo={"https://utimaco.com/themes/custom/utimaco/assets/img/utimaco-logo.svg"}
    />
    <Footer/>
    </>
  )
}

export default HSM