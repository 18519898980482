import React from 'react'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

function ContactUsPage() {
  return (
    <>
    <Navbar/>
    <ContactUs/>
    <Footer/>
    </>
  )
}

export default ContactUsPage