import React, { useState } from "react";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";

import "./form.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const initialFormState = {
  FullName: "",
  Email: "",
  Mobile: "",
  University: "",
  Specialty: "",
  CumulativeAverage: "",
  ExpectedGraduationDate: "",
  PreviousWorkExperience: "",
  WhyYouInterested: "",
  CvMessage: "",
  Attachment: null,
};
const locales = {
  en: { title: "ENG" },
  ar: { title: "AR" },
};

function TrainingFormComponent() {
  const [lang, setLang] = useState(locales.en.title);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const [formState, setFormState] = useState(initialFormState);

  const handleChange = (propertyName) => (event) => {
    setFormState({ ...formState, [propertyName]: event.target.value });
  };

  const handleFileChange = (propertyName) => (event) => {
    const file = event.target.files[0];
    setFormState({ ...formState, [propertyName]: file });
  };

  const submitForm = () => {
    console.log(formState);
    // check if all fields are filled
    let isFormValid = true;
    setLoading(true);
    for (const key in formState) {
      if (formState[key] === "" || formState[key] === null) {
        isFormValid = false;
        setMessage(t("error.fill"));
        setSeverity("error");
        setOpen(true);
        setLoading(false);
        return;
      }
    }

    // send form data to backend
    if (isFormValid) {
      const formData = new FormData();
      for (const key in formState) {
        formData.append(key, formState[key]);
      }
      let DevApi =
        "http://igrctesting-001-site6.etempurl.com/api/CooperativeTrainingRegistration/create";
      let ProdApi =
        "https://www.igrc2.com/api/CooperativeTrainingRegistration/create";
      let api = process.env.NODE_ENV === "development" ? DevApi : ProdApi;
      setLoading(true);
      fetch(api, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setMessage(data?.data);
          setSeverity(data?.isSuccess ? "success" : "error");
          setLoading(false);
          setOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setMessage(t("error.wrong"));
          setSeverity("error");
          setLoading(false);
          setOpen(true);
        });
    }
  };

  return (
    <>
      <div className="wrap container d-flex flex-column my-5" dir="ltr">
        <div className="toggle-btns d-flex justify-content-end">
          <ToggleButtonGroup
            value={lang}
            exclusive
            onChange={(event, newLang) => {
              setLang(newLang);
              i18n.changeLanguage(newLang === "ENG" ? "en" : "ar");
              document.getElementsByClassName("wrap")[0].dir =
                newLang === "ENG" ? "ltr" : "rtl";
            }}
            aria-label="choose language"
          >
            <ToggleButton
              className="toggle-btn"
              value="ENG"
              aria-label="left aligned"
            >
              <span>{t("en")}</span>
            </ToggleButton>
            <ToggleButton
              className="toggle-btn"
              value="AR"
              aria-label="centered"
            >
              <span>{t("ar")}</span>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {/*  */}
        <div className="form-header d-flex justify-content-center">
          <span>{t("apply")}</span>
        </div>
        {/*  */}
        <div className="divider"></div>
        {/*  */}
        <div className="form-wrap d-flex w-100 mb-3">
          <div className="w-60">
            <div className="input-wrap mt-4 d-flex justify-content-between align-items-center w-100">
              <label className="w-60 text-black form-label" htmlFor="FullName">
                {t("form.fullName")}
              </label>
              <input
                className="w-100 form-control"
                type="text"
                id="FullName"
                name="FullName"
                value={formState.FullName}
                onChange={handleChange("FullName")}
              />
            </div>
            {/*  */}
            <div className="input-wrap mt-4 d-flex justify-content-between align-items-center w-100">
              <label className="w-60 text-black" htmlFor="Email">
                {t("form.email")}
              </label>
              <input
                className="form-control w-100"
                type="email"
                id="Email"
                name="Email"
                value={formState.Email}
                onChange={handleChange("Email")}
              />
            </div>
            {/*  */}
            <div className="input-wrap mt-4 d-flex justify-content-between align-items-center w-100">
              <label className="w-60 text-black" htmlFor="Mobile">
                {t("form.phone")}
              </label>
              <input
                className="form-control w-100"
                type="tel"
                id="Mobile"
                name="Mobile"
                value={formState.Mobile}
                onChange={handleChange("Mobile")}
              />
            </div>
            {/*  */}
            <div className="input-wrap mt-4 d-flex justify-content-between align-items-center w-100">
              <label className="w-60 text-black" htmlFor="University">
                {t("form.school")}
              </label>
              <input
                className="form-control w-100"
                type="text"
                id="University"
                name="University"
                value={formState.University}
                onChange={handleChange("University")}
              />
            </div>
            {/*  */}
            <div className="input-wrap mt-4 d-flex justify-content-between align-items-center w-100">
              <label className="w-60 text-black" htmlFor="Specialty">
                {t("form.major")}
              </label>
              <input
                className="form-control w-100"
                type="text"
                id="Specialty"
                name="Specialty"
                value={formState.Specialty}
                onChange={handleChange("Specialty")}
              />
            </div>
            {/*  */}
            <div className="input-wrap mt-4 d-flex justify-content-between align-items-center w-100">
              <label className="w-60 text-black" htmlFor="CumulativeAverage">
                {t("form.gpa")}
              </label>
              <input
                className="form-control w-100"
                type="number"
                id="CumulativeAverage"
                name="CumulativeAverage"
                value={formState.CumulativeAverage}
                onChange={handleChange("CumulativeAverage")}
              />
            </div>
            {/*  */}
            <div className="input-wrap mt-4 d-flex justify-content-between align-items-center w-100">
              <label
                className="w-60 text-black"
                htmlFor="ExpectedGraduationDate"
              >
                {t("form.graduation")}
              </label>
              <input
                className="form-control w-100"
                type="date"
                id="ExpectedGraduationDate"
                name="ExpectedGraduationDate"
                value={formState.ExpectedGraduationDate}
                onChange={handleChange("ExpectedGraduationDate")}
              />
            </div>
            {/*  */}
            <div className="input-wrap mt-4 d-flex flex-column justify-content-between align-items-center w-100">
              <label
                className="w-100 text-black mb-2"
                htmlFor="PreviousWorkExperience"
              >
                {t("form.workExperience")}
              </label>
              <textarea
                className="form-control w-100"
                type="text"
                id="PreviousWorkExperience"
                rows={3}
                name="PreviousWorkExperience"
                value={formState.PreviousWorkExperience}
                onChange={handleChange("PreviousWorkExperience")}
              />
            </div>
          </div>
          <div>
            <div className="input-wrap mt-4 d-flex flex-column justify-content-between align-items-center w-100">
              <label
                className="w-100 text-black mb-2"
                htmlFor="WhyYouInterested"
              >
                {t("form.interested")}
              </label>
              <textarea
                className="form-control w-100"
                type="text"
                id="WhyYouInterested"
                placeholder="Type Here"
                rows={4}
                name="WhyYouInterested"
                value={formState.WhyYouInterested}
                onChange={handleChange("WhyYouInterested")}
              />
            </div>
            <div className="input-wrap mt-4 d-flex flex-column justify-content-between align-items-center w-100">
              <label className="w-100 text-black mb-2" htmlFor="CvMessage">
                {t("form.cover")}
              </label>
              <textarea
                className="form-control w-100 form-control"
                type="text"
                id="CvMessage"
                placeholder="Type Here"
                rows={3}
                name="CvMessage"
                value={formState.CvMessage}
                onChange={handleChange("CvMessage")}
              />
            </div>
            <div className="input-wrap mt-4 d-flex flex-column justify-content-between align-items-center w-100">
              <label className="w-100 text-black mb-2" htmlFor="Attachment">
                {t("form.resume")}
              </label>

              <input
                className="form-control w-100"
                type="file"
                id="Attachment"
                accept=".pdf,.doc,.docx"
                name="Attachment"
                multiple={false}
                onChange={handleFileChange("Attachment")}
              />
            </div>

            <div className="submit-form h-25 d-flex justify-content-center align-items-center">
              <button className="submit-btn" onClick={submitForm}>
                {t("form.SUBMIT")}
              </button>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="mt-5 d-flex justify-content-center">
          <span className="w-75 text-uppercase">{t("thank")}</span>
        </div>
        {/*  */}
      </div>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      {loading && (
        <div className="loading">
          <CircularProgress />
        </div>
      )}
    </>
  );
}

export default TrainingFormComponent;
