import p_img1 from "../src/images/partners/broadcom/Group 5208.webp";
import p_img2 from "../src/images/partners/crowdstrike/Group 5240.webp";
import p_img3 from "../src/images/partners/digitalgaurdian/Group 5226.webp";
import p_img4 from "../src/images/partners/gaurduim/Group 5223.webp";
import p_img6 from "../src/images/partners/ibm/Group 5203.webp";
import p_img7 from "../src/images/partners/microsoft/Group 5192.webp";
import p_img8 from "../src/images/partners/oneidentity/Group 5237.webp";
import p_img9 from "../src/images/partners/spirion/Group 5244.webp";
import p_img10 from "../src/images/partners/symantec/Group 5185.webp";
import p_img11 from "../src/images/partners/trend/Group 5189.webp";
import p_img12 from "../src/images/partners/utimaco/Group 5217.webp";
import p_img13 from "../src/images/partners/utimacoblue/Group 5213.webp";
import p_img14 from "../src/images/partners/vectra/Group 5199.webp";
import p_img15 from "../src/images/partners/vgtrust/Group 5248.webp";
import p_img16 from "../src/images/partners/vm/Group 5229.webp";
import icon1 from "../src/images/whyIcons/icon1/Group 4871.webp";
import icon2 from "../src/images/whyIcons/icon2/Group 5166.webp";
import icon3 from "../src/images/whyIcons/icon3/Group 5171.webp";
import icon4 from "../src/images/whyIcons/icon4/Group 5176.webp";
import about1 from "../src/images/about1/Group 5140.webp";
import about2 from "../src/images/about2/Group 5149.webp";
import about3 from "../src/images/about3/Group 5141.webp";
import about4 from "../src/images/about4/Group 5146.webp";
import about5 from "../src/images/about5/Group 5143.webp";
import about6 from "../src/images/about6/Group 5152.webp";
import serv1 from "../src/images/servicecardicons/Layer 1.svg";
import serv2 from "../src/images/servicecardicons/Layer 2.svg";
import serv3 from "../src/images/servicecardicons/Layer 3.svg";
import serv4 from "../src/images/servicecardicons/Layer 4.svg";
import serv5 from "../src/images/servicecardicons/Layer 5.svg";
import serv6 from "../src/images/servicecardicons/Layer 6.svg";

export const partners = [
  {
    id: 1,
    image: p_img1,
    link: "https://www.broadcom.com/",
  },
  {
    id: 2,
    image: p_img2,
    link: "https://www.crowdstrike.com/?utm_campaign=brand&utm_content=&utm_medium=sem&utm_source=goog&utm_term=crowdstrike&gclid=CjwKCAjwlcaRBhBYEiwAK341jRHoGRCmyl7oiqi6GZNnXbDrhEFMBsdGSF27CG-LquPKElwp4nt-RBoCGtoQAvD_BwE",
  },
  {
    id: 3,
    image: p_img3,
    link: "https://digitalguardian.com/",
  },
  {
    id: 4,
    image: p_img4,
    link: "https://www.ibm.com/eg-en/products/ibm-guardium-data-protection",
  },
  {
    id: 5,
    image: p_img6,
    link: "https://www.ibm.com/us-en/",
  },
  {
    id: 6,
    image: p_img7,
    link: "https://www.microsoft.com/en-eg",
  },
  {
    id: 7,
    image: p_img8,
    link: "https://www.oneidentity.com/",
  },
  {
    id: 8,
    image: p_img9,
    link: "https://www.spirion.com/",
  },
  {
    id: 9,
    image: p_img10,
    link: "https://www.broadcom.com/products/cyber-security",
  },
  {
    id: 10,
    image: p_img11,
    link: "https://www.trendmicro.com/en_us/business.html",
  },
  {
    id: 11,
    image: p_img12,
    link: "https://utimaco.com/",
  },
  {
    id: 12,
    image: p_img13,
    link: "https://utimaco.com/secure-web-conferencing",
  },
  {
    id: 13,
    image: p_img14,
    link: "https://www.vectra.ai/?utm_term=vectra%20security&utm_source=google&utm_campaign=CC_Brand_Global&utm_medium=cpc&utm_content=549400382086&gclid=CjwKCAjwlcaRBhBYEiwAK341jQ7386P6K4pvIc5RMOYJ8rSi6HyPXQdCvkMxDKp2ZiBRG-YwQ8pOTxoCFUQQAvD_BwE&gclid=CjwKCAjwlcaRBhBYEiwAK341jQ7386P6K4pvIc5RMOYJ8rSi6HyPXQdCvkMxDKp2ZiBRG-YwQ8pOTxoCFUQQAvD_BwE",
  },
  {
    id: 14,
    image: p_img15,
    link: "https://vigitrust.com/",
  },
  {
    id: 15,
    image: p_img16,
    link: "https://www.vmware.com/mena.html",
  },
];

export const why_data = [
  {
    id: 1,
    img: icon1,
    title: "SCALABLE",
    title2: "Agility",
    title3: "Entrepreneurship",
    label: "SCALABLE",
    list1: [
      { tetx: "Large Network of trigger-contract" },
      { tetx: "peer professionals" },
      { tetx: "Solid base of partners" },
      { tetx: "Professional Connections" },
    ],
    list2: [
      { tetx: "One-step ahead market needs &" },
      { tetx: "foreseen regulations" },
      { tetx: "Hands-on Training" },
      { tetx: " Diversified Portfolio" },
      { tetx: "Presales & Project MGMT" },
    ],
  },
  {
    id: 2,
    img: icon2,
    title: "TRUSTWORTHY",
    title2: "Project Management",
    title3: "Knowledge Transfer",
    label: "TRUSTWORTHY",
    list1: [
      { tetx: "Three interconnected reviews:" },
      { tetx: "Project Performance" },
      { tetx: "Quality Assurance" },
      { tetx: " Team Performance" },
    ],
    list2: [
      { tetx: "One-Team Approach" },
      { tetx: "Professional advisory &" },
      { tetx: "transparent reporting" },
      { tetx: "Para-project services" },
    ],
  },

  {
    id: 3,
    img: icon3,
    title: "PROTECTION",
    title2: "Contractual Commitment",
    title3: "SLA Management",
    label: "PROTECTION",
    list1: [
      { tetx: "Clear SOW Staging" },
      { tetx: "Flexible FTE-Based options" },
      { tetx: "Deliverables Management " },
      { tetx: "Practice" },
    ],
    list2: [
      { tetx: "ITIL compliant MSP" },
      { tetx: "PMP-based project approach" },
      { tetx: "Multiple escalation points & levels" },
    ],
  },

  {
    id: 4,
    img: icon4,
    title: "HIGH SECURITY",
    title2: "NDA Agreements",
    title3: "Entrepreneurship",
    label: "HIGH SECURITY",
    list1: [
      { tetx: "Standard & According to customer needs" },
      { tetx: "Per Project & Talent" },
      { tetx: "Previous work record" },
    ],
    list2: [
      { tetx: "Infrastructure Services" },
      { tetx: "Application Services" },
      { tetx: "Para-Info Services" },
      { tetx: "Global Business Administration" },
    ],
  },
];

export const aboutROW1Data = [
  {
    id: 1,
    img: about1,
    title:
      "Compliance with growing regulatory  .legislations (Governmental/Industrial: CBE regulations, FATCA, GDRP,…)",
  },
  {
    id: 2,
    img: about2,
    title: "Business-sense of accountability & shareholders legal liability",
  },
  {
    id: 3,
    img: about3,
    title: "On-Demand competitive FTE consumption-based InfoSec services (MSP)",
  },
];

export const aboutROW2Data = [
  {
    id: 1,
    img: about4,
    title: "GRC Class-A talents",
  },
  {
    id: 2,
    img: about5,
    title: "Credible Regional Consultancy Partners (RGPs)",
  },
  {
    id: 3,
    img: about6,
    title: "Intrusion of Cloud",
  },
];

export const trendmecrodata = [
  "Trend Micro",
  "Automated",
  "Trend Micro Apex One™ protection offers advanced automated threat detection and response against an ever-growing variety of threats, including fileless and ransomware. Our cross-generational blend of modern techniques provides highly tuned endpoint protection that maximizes performance and effectiveness.",
  "Insightful",
  "Get actionable insights, expanded investigative capabilities, and centralized visibility by using an advanced EDR toolset, strong SIEM integration, and an open API set. You have the option to perform correlated, extended threat investigations that go beyond the endpoint and augment your security teams with a managed detection and response service.",
  "All-in-one",
  "In an evolving technological landscape, you need security that goes beyond traditional antivirus. Apex One offers threat detection, response, and investigation within a single agent. Eliminate multiple vendors and consoles and gain deployment flexibility through both SaaS and on-premises deployment options.",
];

export const DGtxt =
  "Digital Guardian provides out-of-the-box data classification that can automatically locate and identify your sensitive data then apply labels such as Public, Internal, Confidential or Restricted to classify and determine how the data is handled from one single DLP agent.";

export const DG2txt1 =
  "Digital Guardian offers a full suite of enterprise security products including Data Loss Prevention (DLP) modules which are designed to safeguard critical data assets and protect company endpoints from every possible avenue of attack.";
export const DG2txt2 =
  "Digital Guardian DLP provides content inspection works by registering sensitive data that you want to secure. This data can be in almost any form, including a file share full of PDF and Microsoft Word documents, a database with millions of customer records, or intellectual property such as source code, CAD designs, or business plans. As it is registered, the data is either represented by a “fingerprint,” or is recorded whole (if it is short and discrete).Registered data is then incorporated into a policy. The policy can be associated with one or more inspection services (Data in Motion, Data at Rest), and further focused to target selected users, groups, locations, etc. In the case of Data at Rest, when the inspection service is run, it will discover every eligible file and look for sensitive data within on-premises and cloud-hosted applications. It can scan data on file servers, email servers, databases, and content collaboration applications such as Microsoft SharePoint. Also, it can discover and remediate sensitive data stored on laptop and desktop systems. For Data in Motion, it will scan the network traffic as it is transferred from one location to another through email and over web channels such as HTTP, HTTPS, and FTP.";
export const PKItxt =
  "Public Key Infrastructure is considered integral to business security processes, transactions and access control -  the secure storage and protection of private keys is paramount to the security of PKI.";
export const KEYtxt =
  "UTIMACO provides solutions for secure key management as well as for centralized HSM management and monitoring to address the challenge of managing crypto secrets. All solutions provide a single pane of glass for centralized and transparent monitoring, control and management, as well as audit logs to assist in attestation and high capacity.";
export const CRYPTOtxt1 =
  "UTIMACO Crypto Server and u.Trust Anchor hardware security modules (HSM) are general-purpose cryptographic devices. HSMs provide state of the art security for commercial applications, such as certificate services (PKI), electronic Signature (eIDAS), access and identity management (IAM) in the Internet of Things (IoT), Secure SSL, WAF (Web Application Firewall).";
export const CRYPTOtxt2 =
  "This list is not comprehensive: Applications that make use of cryptography are targets for integration with an HSM. The firmware supplied with the HSM provides standard interfaces and APIs to protect cryptographic keys. Combined, the firmware and the hardware enforce the use of the keys within a secure, tamper-evident, tamper-resistant physical boundary, certified by external security labs.";
export const CRYPTOtxt3 =
  "This list is not comprehensive: Applications that make use of cryptography are targets for integration with an HSM. The firmware supplied with the HSM provides standard interfaces and APIs to protect cryptographic keys. Combined, the firmware and the hardware enforce the use of the keys within a secure, tamper-evident, tamper-resistant physical boundary, certified by external security labs.";

export const items1 = [
  { content: " Cyprto Server", link: "/cryptoserver" },
  { content: "Key Management", link: "/key" },
  { content: "Payment HSM", link: "/hsm" },
  { content: "PKI", link: "/pki" },
];

export const items2 = [
  { content: "Data Classification", link: "/dg" },
  { content: "DLP", link: "/dg2" },
];
export const items3 = [{ content: "Trend Micro", link: "/trendmecro" }];
export const items4 = [{ content: "NDR", link: "#" }];
export const items5 = [{ content: "IBM", link: "/ibm" }];

export const servicesRow1 = [
  {
    img: serv1,
    title: "CYBERSECURITY SERVICES",
    desc: "IGRC² team can protect your key information assets across networks, hosts, applications, databases, web-based applications. Also, protect data and email from information leakage, viruses, Trojans, spyware, and malicious code distributed via email or other communication services, by outsourcing and managing our technologies to your site.Keeping up with cyber threats is becoming increasingly challenging, but you can rely on us to provide you with totally managed top security solutions with 24X7 monitoring and incidence response",
  },
  {
    img: serv2,
    title: "DIGITAL TRANSFORMATION",
    desc: "Companies are trying to innovate and digitally transform to drive business growth and differentiation against fierce competition. IGRC² enables you to transform faster and find new ways of working to succeed in a digital world by delivering integrated propositions using the industry-leading capabilities of both companies that provide the building blocks for end-to-end customer journeys and use cases",
  },
  {
    img: serv3,
    title: "COMPLIANCE SERVICE",
    desc: "The risk from information systems can be managed by adopting globally accepted controls frameworks such as ISO 27001, ITIL, or CoBIT. Implementing any of these frameworks requires a systematic approach to analyzing the key risk areas, identifying and documenting the controls, and then monitoring and measuring the compliance. Information Risk Management (IRM) can be extended to designing a Business Continuity Strategy and developing and testing business continuity plans",
  },
];
export const servicesRow2 = [
  {
    img: serv4,
    title: "BUILDING CYBERSECURITY PROGRAM",
    desc: "Building an effective cybersecurity program presents challenges to organizations of all sizes, our experienced consultants who have a deep understanding of global and local cybersecurity regulations and managing wide range of cyber risks facing businesses today, helping you implement the best possible security practices and solutions that meet your budget and requirements",
  },
  {
    img: serv5,
    title: "CYBERSECURITY PROGRAM ASSESSMENT",
    desc: "ISO 27001, NIST CSF, NIST 800-37, PCI-DSS, Other etc. We deliver strategic, executive and tactical recommendations, along with an implementation roadmap, to meet your organization’s desired cybersecurity state.",
  },
  {
    img: serv6,
    title: " CISO AS SERVICE",
    desc: "Organizations may have an existing security manager who needs the guidance and support of a senior expert to enhance the cybersecurity program or improve their security compliance. By working with IGRC² CISO, you can tap into the vast experience and certified CISOs. This service will help business to achieve a high level of security without the cost of a full-time, in-house CISO with service offerings from 4 days a month to 5 days a week",
  },
];
export const servicesRow3 = [
  {
    img: serv4,
    title: "CYBERSECURITY RISK MANAGEMENT",
    desc: "Perform assessment of risks across business functions, services, and IT assets. We will support you in Risk treatment based on prioritization, compensating control considerations, and cost benefit analysis. In addition, we will build a practical risk register and provide detailed technical and executive report supported with dashboards, key performance indicator, and key risk indicator measurement.",
  },
  {
    img: serv5,
    title: "THIRD PARTY RISK MANAGEMENT",
    desc: "Developing and overseeing a third-party cybersecurity risk management (TPCRM) program can be extraordinarily resourceintensive. Our IGRC² experienced consultants can help you to build an effective third-party cybersecurity risk management framework and provide a comprehensive suite of services and solutions that enable organizations to operate more successfully while managing third-party cybersecurity risks.",
  },
  {
    img: serv6,
    title: "PRIVACY AND DATA PROTECTION",
    desc: "Organizations may have an existing security manager who needs the guidance and support of a senior expert to enhance the cybersecurity program or improve their security compliance. By working with IGRC² CISO, you can tap into the vast experience and certified CISOs. This service will help business to achieve a high level of security without the cost of a full-time, in-house CISO with service offerings from 4 days a month to 5 days a week.",
  },
];
var d = new Date();
export const jobs = [
  {
    title: "GRC Consultant",
    content:
      "We are hiring GRC Consultant. The ideal candidate will be familiar with Compliance Services and Egypt & KSA Regulations. He will provide our clients with guidance pertaining to security and privacy regulatory and industry-standard requirements, security risk assessments, and GRC consultation services.",
    type: "Full Time",
    kind: "Hybrid",
    level: "1 - 3 Years",
    posted: `${d.getMonth() + 1} - ${d.getFullYear()}`,
  },

  {
    title: "j2EE Developer Opportunity",
    content:
      "We are hiring a j2EE Developer. The ideal candidate will be familiar with the full software design life cycle. They should have experience in designing, coding, testing and consistently managing applications They should be comfortable coding in a number of languages and have an ability to test code in order to maintain high-quality code.",
    type: "Full Time",
    kind: "Remote",
    level: "At Least 3 Years",
    posted: `${d.getMonth() + 1} - ${d.getFullYear()}`,
  },

  {
    title: "Account Manager",
    content:
      "You will utilize a consultative approach, discuss business issues with prospects and develop a formal quote, a written sales proposal, or a formal sales presentation addressing their customer needs.",
    type: "Full Time",
    kind: "Hybrid",
    level: "All Levels",
    posted: `${d.getMonth() + 1} - ${d.getFullYear()}`,
  },
  {
    title: "Software Developer (Appian Developer)",
    content:
      "The Appian Developer will be responsible for the creation and maintenance of various applications built using the Appian Business Process Modeling framework ,Configuring solution applications, process models, business rules, user interfaces, and management reports ,Build and maintain integrations with external services via Appian,Execute unit, integration, and system testing,Provide production support for various Appian applications",
    type: "Full Time",
    kind: "Remote",
    level: "All Levels",
    posted: `${d.getMonth() + 1} - ${d.getFullYear()}`,
  },
];
