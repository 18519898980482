import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

import "../App.css";

import x from "../images/logo.svg";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import DropDown from "./DropDown";

function Navbar() {
  const [click, setClick] = useState(false);
  const [hover, setHOver] = useState(false);
  const [button, setButton] = useState(true);
  const handleitem = () => {
    setClick(!click);
  };
  const closemenu = () => {
    setClick(false);
  };

  const showmenu = () => {
    setHOver(!hover);
  };
  const hidemenu = () => {
    setHOver(!hover);
  };

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };
  console.log(button);

  window.addEventListener("resize", showButton);

  return (
    <div className="navbar">
      <div className="navber_contianer">
        <Link to="/" className="navbar_Logo">
          {" "}
          <img src={x} alt="IGRC2 Logo" style={{ height: 35 }}></img>{" "}
        </Link>

        <div className="menu_icon" onClick={handleitem}>
          {click ? (
            <CloseIcon className="CLOSE_ICON" />
          ) : (
            <MenuIcon className="MENU_ICON" />
          )}
        </div>

        <ul className={click ? "nav_menu active" : "nav_menu"}>
          <li className="nav_item">
            <Link to="/" className="nav_links" onClick={closemenu}>
              {" "}
              <p>HOME</p>{" "}
            </Link>
          </li>
          <li className="nav_item">
            <Link to="/services" className="nav_links" onClick={closemenu}>
              {" "}
              <p> SERVICES </p>{" "}
            </Link>
          </li>

          <li className="nav_item">
            <Link
              to="#"
              className="nav_links dropdwonbutton"
              onClick={closemenu}
              onMouseEnter={showmenu}
              onMouseLeave={hidemenu}
            >
              <p>SOULTIONS</p>
              {hover ? <DropDown /> : <></>}
            </Link>
          </li>
          <li className="nav_item">
            <Link to="/contact-us" className="nav_links" onClick={closemenu}>
              {" "}
              <p> CONTACT US </p>{" "}
            </Link>
          </li>
          <li className="nav_item">
            <Link to="/careers" className="nav_links" onClick={closemenu}>
              {" "}
              <p>CAREERS</p>{" "}
            </Link>
          </li>
          <li className="nav_item">
            <Link to="/trainingForm" className="nav_links" onClick={closemenu}>
              {" "}
              <p>Coop Training program</p>{" "}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
