import React from 'react'
import './SoultionInfo.css'

function VIGIHSM({logo}) {
  return (
      <div className='soltions' >
          <div className='solutionContainer'>
              <div className='solution_bg'>
                  <div className='solution_bg_container'>
                  <span className='solution_left'><p>VigiOne</p></span>
                  <span className='solution_right'> <img src={logo} alt="VigiOne" style={{height:40}} loading='lazy'/></span>
                  </div>
              </div>

              <div className='solution_desc'>
                  <div className='solution_desc_conatianer'>
                      <span className='SDESC'>
                          <strong>VigiOne Allows you to:</strong>
                          <br/><br/>
                          <ul>
                              <li>Map Policies & Procedures and Controls to Regulations and Standards</li><br/>
                              <li>Store information and evidence in a single repository</li><br/>
                              <li>Generate up-to-date, customizable, automated Reporting & Analysis</li><br/>
                              <li>Demonstrably maintain security & compliance levels</li><br/>
                              <li>Implement security and adapt to the evolving regulatory environment</li><br/>
                              <li>Upscale and adapt to your organization’s needs</li><br/>
                              <li>Integrate assessors and implementors, internal and external</li><br/>
                          </ul>
                          <br/><br/>
                          <strong>VigiOne Features</strong><br/><br/>
                          <ul>
                              <li>Integration with 3rd party tools e.g. ASV, Terminal.</li><br/>
                              <li>Task assignment and management tool with calendaring.</li><br/>
                              <li>Full reporting, configured by user type and with dynamic features.</li><br/>
                              <li>Role-driven platform based on the “need-to-know” principle.</li><br/>
                              <li>Multi-level organizational and user management.</li><br/>
                              <li>Integrated Security Awareness education and eLearning</li><br/>
                              <li>Straightforward official &customized self-assessment</li><br/>
                              <li>Policy and procedure management & dissemination tool</li><br/>
                              <li>E-Commerce functionality to upgrade as and when needed</li><br/>
                              <li>Integrated Secure Evidence Repository.</li><br/>
                          </ul>


        
                      </span>
                      <div className='learnMore'><a href='https://vigitrust.com/solutions/'  target='_blank' rel="noreferrer" > Learn More ...</a></div>
                  </div>
              </div>

          </div>

      </div>
  )
}

export default VIGIHSM