import React from "react";

import "./Card.css";

function CardItem({ img, title, title2, list1, title3, list2, label }) {
  console.log(list1);
  return (
    <>
      <div className="card_item">
        <div className="card_item-link">
          <div className="card_item_info">
            <div className="tablet2">
              <figure className="card_item_pic_wrab">
                <img className="card_item_img" src={img} alt={label} />
              </figure>
              <h1 className="card_item_text">{title}</h1>
            </div>
            <div className="tablet">
              <div className="info">
                <h3>{title2}</h3>
                <ul>
                  {list1.map((items, index) => (
                    <li key={index}>{items.tetx}</li>
                  ))}
                </ul>
              </div>

              <div className="info2">
                <h3>{title3}</h3>
                <ul>
                  {list2.map((items, index) => (
                    <li key={index}>{items.tetx}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardItem;
