import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import SoultionsInfo from '../components/SoultionsInfo'
import { CRYPTOtxt1, CRYPTOtxt2, CRYPTOtxt3 } from '../Data'

function CryptoServer() {
  return (
    <>
    <Navbar/>
    <SoultionsInfo
    Maintitle={"UTIMACO CryptoServer"}
    logo={"https://utimaco.com/themes/custom/utimaco/assets/img/utimaco-logo.svg"}
    desc1={CRYPTOtxt1}
    desc2={CRYPTOtxt2}
    desc3={CRYPTOtxt3}
    link={"https://utimaco.com/products/categories/general-purpose-solutions"}/>
    <Footer/>
    </>
  )
}

export default CryptoServer