
import React from 'react'
import Navbar from '../components/Navbar';
import Carousel_Front from '../components/Carousel_Front';
import About_US from '../components/About_US';
import Why_IGRC from '../components/Why_IGRC';
import Our_partners from '../components/Our_partners';
import Footer from '../components/Footer';

function Home() {
  return (
    <>
    <Navbar/>
   <Carousel_Front/>
   <About_US/>
   <Why_IGRC/>
   <Our_partners/>
   <Footer/>
  </>
  )
}

export default Home