import {BrowserRouter as  Router , Route ,Switch } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Pay from './components/Pay';
import ContactUsPage from './pages/Contact-Us';
import CryptoServer from './pages/CryptoServer';
import DG from './pages/DG';
import DG2 from './pages/DG2';
import Home from './pages/Home';
import HSM from './pages/HSM';
import IBM from './pages/IBM';

import JoinUs from './pages/JoinUs';
import KEY from './pages/KEY';
import PKI from './pages/PKI';
import Services from './pages/Services';
import TrendMecro from './pages/TrendMecro';
import VIGI from './pages/VIGI';
import TrainingProgram from './pages/traning-appliction';





function App() {
  return (
    <div className='App'>

    <Router>
      <Switch>
        <Route exact path="/"><Home/></Route>
        <Route path="/contact-us"> <ContactUsPage/></Route>
        <Route path="/careers"> <JoinUs/></Route>
        <Route path="/services"><Services/></Route>
        <Route path="/ibm"><IBM/></Route>
        <Route path="/trendmecro"><TrendMecro/></Route>
        <Route path="/dg"><DG/></Route>
        <Route path="/dg2"><DG2/></Route>
        <Route path="/pki"><PKI/></Route>
        <Route path="/key"><KEY/></Route>
        <Route path="/cryptoserver"><CryptoServer/></Route>
        <Route path="/vigi"><VIGI/></Route>
        <Route path="/hsm"><HSM/></Route>
        <Route path="/trainingForm"><TrainingProgram/></Route>
      </Switch>
    </Router>

    </div>


  );
}

export default App;
