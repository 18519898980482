import React from "react";
import "./Footer.css";
import x from "../images/white_logo.png";
import map from "../images/map.png";
import map1 from "../images/egy map.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Instagram } from "@mui/icons-material";

function Footer() {
  return (
    <div className="footer">
      <div className="Footer_COntianer">
        <div className="f1">
          <span className="f1_logo">
            <img src={x} alt="IGRC2 Logo" className="res"></img>
          </span>
          <span className="f1_logo_info">
            <p>
              Protect Your Business Now <br /> Through Better Security <br />
              Intelligence With Us.
            </p>
          </span>
          <span className="f1_logos">
            <a
              href="https://www.linkedin.com/company/igrc2/mycompany/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <LinkedInIcon style={{ width: 35, height: 35 }} />
            </a>
            <a
              href="https://www.facebook.com/IGRC2"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <FacebookIcon style={{ width: 35, height: 35 }} />
            </a>
            <a
              href="https://twitter.com/IGRC_2"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <TwitterIcon style={{ width: 35, height: 35 }} />
            </a>
            <a
              href="https://www.instagram.com/igrc_2/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <Instagram style={{ width: 35, height: 35 }} />
            </a>
          </span>
        </div>
        <div className="f2">
          <h1 className="f2_header">SERVICES</h1>
          <span className="f2_info_container">
            <span className="f2_info">
              <span style={{ marginRight: 5 }}>
                <ArrowForwardIosIcon
                  sx={{ width: 12, height: 12, color: "#e9e8e8" }}
                />{" "}
              </span>{" "}
              <p className="f2_p">Cyber Security Services</p>
            </span>

            <span className="f2_info">
              <span style={{ marginRight: 5 }}>
                <ArrowForwardIosIcon
                  sx={{ width: 12, height: 12, color: "#e9e8e8" }}
                />{" "}
              </span>{" "}
              <p className="f2_p">Digital Transformation</p>
            </span>

            <span className="f2_info">
              <span style={{ marginRight: 5 }}>
                <ArrowForwardIosIcon
                  sx={{ width: 12, height: 12, color: "#e9e8e8" }}
                />{" "}
              </span>{" "}
              <p className="f2_p">Compliance Service</p>
            </span>
          </span>

          <span className="f2_2022">
            <p>COPYRIGHT © 2022. ALL RIGHTS RESERVED.</p>{" "}
          </span>
        </div>
        {/* <div className="f3">
          <h1 className="f3_header">KSA</h1>

          <span className="f3_info_container">
            <div className="f3_infoo">
              <span className="f3_txt">
                {" "}
                <p>
                  Riyadh’s Business Gate, Level 1,
                  <br /> Building 7, Zone A - P.O Box <br />
                  93597 - Airport road, Riyadh
                  <br /> 11683, KSA
                </p>{" "}
              </span>

              <span className="f3_txt">
                {" "}
                <p> mail: info@igrc2.com </p>{" "}
              </span>
              <span className="f3_txt">
                {" "}
                <p>
                  {" "}
                  Landline Tel :011-261-1474 <br /> Phone: +966 505712227{" "}
                </p>{" "}
              </span>
            </div>
          </span>

          <span className="xx">
            <img src={map} alt="location" className="mapa" loading="lazy" />
          </span>
        </div> */}
        <div className="f4">
          <h1 className="f3_header">Saudi Arabia</h1>

          <span className="f3_info_container">
            <div className="f3_infoo">
              {/* <span className="f3_txt">
                {" "}
                <p>
                Saudi Arabia
                </p>{" "}
              </span> */}

              <span className="f3_txt">
                {" "}
                <p> mail: info@igrc2.com </p>{" "}
              </span>
              <span className="f3_txt">
                {" "}
                <p>
                  {" "}
                  {/* <span>Landline Tel: +2022626813 </span> */}
                  {/* <br /> */}
                  <span>Phone: 0505712227</span>{" "}
                </p>{" "}
              </span>
            </div>
          </span>

          {/* <span className="xx">
            <img src={map1} alt="location" className="mapa" loading="lazy" />
          </span> */}
        </div>
      </div>
    </div>
  );
}

export default Footer;
