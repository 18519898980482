import React from 'react'
import './ServcieCart.css'
import img from '../images/servicecardicons/Layer 1.svg'


function ServiceCart({category , logo , title , desc}) {
  return (
    <div className={`SerItem${category}`}>
        <div className='Ser_item-link'>
            
        <div className='Ser_item_info'>
                    <div className='Sertablet2'>
                <figure className='Ser_item_pic_wrab' >
                    <img className='Ser_item_img' src={logo} alt={title} />
                </figure>
                    <h1 className={`Ser_item_text${category}`}>{title}</h1>
                    <p className={`Ser_item_desc${category}`}>{desc} </p>
                    </div>

                </div>

        </div>

    </div>
  )
}

export default ServiceCart