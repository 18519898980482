import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import SoultionsInfo from '../components/SoultionsInfo'
import { PKItxt } from '../Data'

function PKI() {
  return (
    <>
    <Navbar/>
    <SoultionsInfo
    Maintitle={"PKI"}
    logo={"https://utimaco.com/themes/custom/utimaco/assets/img/utimaco-logo.svg"}
    desc1={PKItxt}
    link={"https://utimaco.com/products/categories/pki-solutions"}/>
    <Footer/>
    </>
  )
}

export default PKI